import React from 'react'

function Footer() {
    return (
        <div className='footer' >
            <h3 style={{fontSize:'17px'}} >We are not affiliated with TikTok, Douyin or Bytedance.</h3>

           <h4 style={{opacity:'0.5', fontWeight:'100'}} >TOKdownload Copyright © 2022</h4> 
        </div>
    )
}

export default Footer