import React, { useEffect, useState } from "react";
import { NEXT_PUBLIC_PATH } from "../services/constents";
import { useNavigate } from "react-router-dom";
import { ApiRequests } from "../services/apiRequests";
import { useDispatch } from "react-redux";
import { authenticateAsyncThunk } from "../store/authSlice";

export default function Authenticate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function hanldleAuthentication() {
    const refresh = JSON.parse(localStorage.getItem("app-refresh"));
    const access = JSON.parse(localStorage.getItem("app-access"));
    const user = JSON.parse(localStorage.getItem("user"));

    const tokensObj = {
      tokens: {
        refresh,
        access,
      },
      user,
    };

    try {
      const response = await ApiRequests.authenticate({ refresh });

      if (response.status === 200) {
        const urlEncodedData = encodeURIComponent(JSON.stringify(tokensObj));
        const link = `${NEXT_PUBLIC_PATH}/?cookie=${urlEncodedData}`;
        window.location.href = link;
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error:", error);
      navigate("/login");
    }
  }

  useEffect(() => {
    hanldleAuthentication();
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h3 style={{ color: "black" }}>
        Please Wait... Redirecting to Dashboard
      </h3>
    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import { NEXT_PUBLIC_PATH } from "../services/constant";
// import { redirect, useNavigate } from "react-router-dom";
// import { ApiRequests } from "../services/ApiRequests";

// export default function Authenticate() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const refresh = JSON.parse(localStorage.getItem("app-refresh"));
//     const access = JSON.parse(localStorage.getItem("app-access"));
//     const user = JSON.parse(localStorage.getItem("user"));
//     // localStorage.getItem("redirectToAdmin", "true");

//     // if (!refresh || !access || !user) {
//     //   navigate("/login");
//     // }

//     const tokensObj = {
//       tokens: {
//         refresh,
//         access,
//       },
//       user,
//     };

//     console.log("tokens::: obj:: ", tokensObj);

//     const urlEncodedData = encodeURIComponent(JSON.stringify(tokensObj));

//     console.log("uri encdoed Data:: ", urlEncodedData);

//     const link = `${NEXT_PUBLIC_PATH}/?cookie=${urlEncodedData}`;
//     window.location.href = link;
//   }, []);

//   return (
//     <div style={{ height: "200px", display: "flex", alignItems: "center" }}>
//       <h3 style={{ marginLeft: "10px", color: "white" }}>
//         Please Wait... Redirecting to Dashboard
//       </h3>
//     </div>
//   );
// }
