import apiService from "./apiService";
import { APIurls, basePath } from "./constents";

import axios from "axios";

export const ApiRequests = {
  register: async (data) =>{
    const response = await apiService.post(`${basePath}/${APIurls.register}`, data)
    return response;
  },
    
  login: async (data) =>
    await apiService.post(`${basePath}/${APIurls.login}`, data),
  authenticate: async (data) =>
    await apiService.get(`${basePath}/${APIurls.authenticate}`),
  logout: async (data) =>
    await apiService.post(`${basePath}/${APIurls.logout}`, data),
  forget: async (data) =>
    await apiService.post(`${basePath}/${APIurls.forget}`, data),
  reset: async (data) =>
    await apiService.post(
      `${basePath}/${APIurls.reset}`,
      { password: data.password },
      {
        params: { token: data.token },
      }
    ),


  // reset: async (data, token) => await apiService.post(`${basePath}/${APIurls.reset }` + "?token=" + token, data, {withCredentials: true,}),
};