import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { ApiRequests } from "../services/apiRequests";
import {
  catchAsync,
  handleLoadingErrorParamsForAsycThunk,
  reduxToolKitCaseBuilder,
} from "./detectError";
import "react-toastify/dist/ReactToastify.css";
import { app_url } from "../services/constents";
import toast from "react-hot-toast";

// user Login With Credentials
export const userLoginAsyncThunk = createAsyncThunk(
  "auth/userLoginAsyncThunk",
  catchAsync(async ({ user, cb, navigate }, _) => {
    const response = await ApiRequests.login(user);
    console.log("response::: ", response);
    if (response) {
      if (response?.status == 200) {
        if (cb) cb(response.data);
        // toast.success("You have Logged In!", {
        //   autoClose: 2000,
        // });
        navigate && navigate("/");
      } else {
        toast.error(response.error);
      }
    }
    return response?.data;
  })
);
// user Login With Credentials
export const authenticateAsyncThunk = createAsyncThunk(
  "auth/authenticateAsyncThunk",
  catchAsync(async (__, _) => {
    const response = await ApiRequests.authenticate();
    return response?.data;
  })
);
// user Login With Credentials
export const refreshTokensAsyncThunk = createAsyncThunk(
  "auth/refreshTokensAsyncThunk",
  catchAsync(async ({ callBack }, _) => {
    // const response = await ApiRequests.refreshTokens({ refreshToken: JSON.parse(refreshToken) });
    const refreshToken = JSON.parse(localStorage.getItem("app-refresh-token"));
    const response = await ApiRequests.refreshTokens({ refreshToken });
    console.log("response>>>", response);
    if (callBack) callBack();
    if (response) {
      if (response?.status == 200) {
        // history("/")
      } else {
        toast.error(response.error);
      }
    }
    return response.data;
  })
);
export const userLogoutAsyncThunk = createAsyncThunk(
  "auth/userLogoutAsyncThunk",
  catchAsync(async ({navigate}) => {
    const refreshTokenObj = localStorage.getItem("app-refresh");
    const refreshToken = refreshTokenObj && JSON.parse(refreshTokenObj).token;
    const response = await ApiRequests.logout({ refreshToken });
    console.log("respose:::", response);
    if (response) {
      if (response?.status == 204) {
        toast.success("LogOut Successfully!!!");
        localStorage.removeItem("app-access-token");
        localStorage.removeItem("app-refresh-token");
        localStorage.removeItem("user");
        localStorage.removeItem("isUserLoggedIn");
        localStorage.removeItem("app-access");
        localStorage.removeItem("app-refresh");
        // window.location.reload()
        navigate('/login')
      } else {
        toast.error(response.error);
      }
    }
    return true;
  })
);

// user register With Credentials
export const userRegisterAsyncThunk = createAsyncThunk(
  "auth/userRegisterAsyncThunk",
  catchAsync(async ({ email, password, name, navigate }) => {
    console.log("email, pass, name:: ", email, password, name);
    const response = await ApiRequests.register({ email, password, name });
    if (response) {
      if (response?.status == 201) {
        toast.success("Registered Successfully!!!", {
          autoClose: 2000,
        });
        navigate('/login')
        // router.push('/login')
      } else {
        toast.error(response.error);
      }
    }
    return response?.data;
  })
);
// forget
export const userForgetAsyncThunk = createAsyncThunk(
  "auth/userForgetAsyncThunk",
  async ({ email, router }) => {
    console.log("forgot email: ", email);
    try {
      const response = await ApiRequests.forget({ email });
      if (response && response?.status === 201) {
        toast.success(
          "Password reset link sent successfully! Please check your mail box or junk box.",
          {
            autoClose: 2000,
          }
        );
      } else {
        toast.error("No user found with this email. Check your emial and try again");
      }
      return response?.data;
    } catch (error) {
      toast.error("No user found with this email.");
      throw error;
    }
  }
);

export const userResetAsyncThunk = createAsyncThunk(
  "auth/userResetAsyncThunk",
  async ({ password, token, navigate }) => {
    console.log("password:: ", password);
    console.log("token:: ", token);
    try {
      const response = await ApiRequests.reset({ password, token });
      if (response && response?.status === 204) {
        toast.success("Password changed successfully!", {
          autoClose: 2000,
        });
        navigate("/");
      } else {
        toast.error("Failed . Please try again later.");
      }
      return response?.data;
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
      throw error;
    }
  }
);

const user = () => {
  try {
    let user = localStorage.getItem("user");
    if (user) user = JSON.parse(user);
    return user ?? {};
  } catch (error) {
    return {};
  }
};
const initialState = {
  user: user(),
  isLoggedIn: false,
  // manage states
  errors: {},
  loadings: {},
  errorMessages: {},
  errorCodes: {},
  paramsForThunk: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    storeUser: (state, action) => {
      state.user = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setTokens(state, action) {
      state.tokens = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLoginAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload?.user;
        state.isLoggedIn = true;
        localStorage.setItem(
          "app-access-token",
          action.payload?.tokens?.access.token
        );
        localStorage.setItem(
          "app-access",
          JSON.stringify(action.payload?.tokens?.access)
        );
        localStorage.setItem(
          "app-refresh",
          JSON.stringify(action.payload?.tokens?.refresh)
        );
        localStorage.setItem(
          "app-refresh-token",
          action.payload?.tokens?.refresh?.token
        );
        localStorage.setItem("user", JSON.stringify(action.payload?.user));
        localStorage.setItem("isUserLoggedIn", JSON.stringify(true));
      })
      .addCase(userLogoutAsyncThunk.fulfilled, (state, action) => {
        state.user = null;
        state.isLoggedIn = false;
      })
      .addCase(userRegisterAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(authenticateAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload?.user;
        state.isLoggedIn = true;
        localStorage.setItem("user", JSON.stringify(action.payload?.user));
      })
      .addCase(refreshTokensAsyncThunk.fulfilled, (state, action) => {
        // state.user = action.payload;
        console.log("refreshTokensAsyncThunk:", action.payload);
        localStorage.setItem(
          "app-access-token",
          JSON.stringify(action.payload?.access?.token)
        );
        localStorage.setItem(
          "app-refresh-token",
          JSON.stringify(action.payload?.refresh?.token)
        );
      })

      // im using addMatcher to manage the asyncthunksMehtod actions like fullfilled,pending,rejected and also to manage the errors loading and error messages and async params
      .addMatcher(
        // isAsyncThunk will run when the action is an asyncthunk exists from giver asycntthunks
        isAnyOf(
          // reduxToolKitCaseBuilder helper make fullfilled, pending, and rejected cases
          ...reduxToolKitCaseBuilder([
            userLoginAsyncThunk,
            userRegisterAsyncThunk,
            refreshTokensAsyncThunk,
            authenticateAsyncThunk,
            userLogoutAsyncThunk,
            userForgetAsyncThunk,
            userResetAsyncThunk,
          ])
        ),
        handleLoadingErrorParamsForAsycThunk
      );
  },
});

export default authSlice.reducer;
export const { setLoading, storeUser, setUser, setTokens } = authSlice.actions;
