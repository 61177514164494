import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userLogoutAsyncThunk } from "../store/authSlice";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(userLogoutAsyncThunk({navigate}));
  }, []);
  
  return <div>Logout</div>;
};

export default Logout;
